import { gql, useLazyQuery } from "@apollo/client";
import { SquareRounded } from "@mui/icons-material";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ArcElement,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  Tooltip,
} from "chart.js";
import { format } from "date-fns";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Panel, PanelTitle } from "../../../../components/Panel";
import { useDateRange } from "./context/date-range";

// === gql
export const QUERY_STATISTICS = gql`
  query Statistics($date: Date, $dateTo: Date) {
    statistics {
      Overview(date: $date, dateTo: $dateTo) {
        ActorDistribution {
          Roles {
            Role
            Total
          }
          Total
        }
      }
    }
  }
`;

// === type
type ActorDistributionType = {
  Roles: {
    Total: number;
    Role: string;
  }[];
  Total: number;
};

type Statistics = {
  ActorDistribution: ActorDistributionType;
};

type ChartDataType = {
  label: string;
  value: number;
  color?: string;
};

ChartJS.register(ArcElement, Tooltip, Legend);

const options: ChartOptions<"doughnut"> = {
  cutout: "80%",
  plugins: {
    legend: {
      display: false,
    },
  },
};

// FIXME: dynamic colors
const bgColors = ["#7DB1B9", "#ABC38D", "#7DDFD2", "#B5E2AF"];

type QueryVariable = {
  date: string | null;
  dateTo: string | null;
};

const ActorRoles = () => {
  const { range } = useDateRange();

  const [getData, { data: dataStatistics }] = useLazyQuery<
    { statistics?: { Overview?: Statistics } },
    QueryVariable
  >(QUERY_STATISTICS);

  const [data, setData] = useState<ChartDataType[]>([]);
  const [total, setTotal] = useState<number>(0);

  const getDataDebounce = useRef(
    debounce((variables: QueryVariable) => {
      getData({
        variables,
      });
    }, 2000)
  );

  useEffect(() => {
    getDataDebounce.current({
      date: range.startDate ? format(range.startDate, "yyyy-MM-dd") : null,
      dateTo: range.endDate ? format(range.endDate, "yyyy-MM-dd") : null,
    });
  }, [range]);

  useEffect(() => {
    if (dataStatistics?.statistics) {
      const actorsData =
        dataStatistics?.statistics?.Overview?.ActorDistribution;

      const data: ChartDataType[] = [];

      if (actorsData) {
        actorsData.Roles.forEach((role, i) => {
          data.push({
            label: role.Role,
            value: role.Total,
            color: bgColors[i],
          });
        });
        setTotal(actorsData.Total);
      }

      setData(data);
    }
  }, [dataStatistics]);

  return (
    <Panel
      minimizable={false}
      sx={{ height: "100%" }}
      header={<PanelTitle>Fordeling af aktører</PanelTitle>}
    >
      <Chart data={data} total={total} />
    </Panel>
  );
};

const LegendCell: React.FC<{ color: string }> = ({ children, color }) => (
  <>
    <Box sx={{ position: "relative", pl: "22px" }}>
      <SquareRounded
        sx={{
          color: color,
          position: "absolute",
          left: 0,
          top: "2px",
          fontSize: 16,
        }}
      />
      {children}
    </Box>
  </>
);

const Chart = ({ data, total }: { data: ChartDataType[]; total: number }) => {
  const [chartData, setChartData] = useState<ChartData<"doughnut", number[]>>({
    datasets: [],
  });

  useEffect(() => {
    setChartData({
      labels: data.map((d) => d.label),
      datasets: [
        {
          data: data.map((d) => d.value),
          backgroundColor: data.map((d) => d.color),
        },
      ],
    });
  }, [data]);

  return (
    <>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sm={5}>
          <Box sx={{ position: "relative" }}>
            <Doughnut data={chartData} options={options} />
            <Box
              sx={{
                position: "absolute",
                maxWidth: "100%",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <Typography fontSize={13}>Antal aktører</Typography>
              <Typography fontSize={18} fontWeight={700}>
                {total}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={7}>
          <Table
            sx={{
              "& .MuiTableCell-root": {
                fontSize: 13,
              },
              "& .MuiTableCell-footer, & .MuiTableCell-head": {
                borderBottom: "none",
                fontWeight: 500,
                color: "inherit",
              },
              "& .MuiTableCell-body": {
                borderBottom: "none",
              },
              "& .MuiTableBody-root": {
                "& .MuiTableRow-root": {
                  "&:nth-of-type(odd)": {
                    backgroundColor: (theme) => theme.palette.grey[50],
                  },
                },
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell align="center">Aktører</TableCell>
                <TableCell align="center">%</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((dt, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <LegendCell color={dt.color ?? "#FFF"}>
                      {dt.label}
                    </LegendCell>
                  </TableCell>
                  <TableCell align="center">{dt.value}</TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: dt.color, fontWeight: 500 }}
                  >
                    {((dt.value / total) * 100).toFixed(2)}%
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ "& .MuiTableCell-root": { fontWeight: 500 } }}>
                <TableCell>Total</TableCell>
                <TableCell align="center">{total}</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
};

export default ActorRoles;
