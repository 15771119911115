import React, { createContext, useCallback, useContext, useState } from "react";

// === types

type Filter = {
  text: string;
  sellers: string[];
  status: string;
  favorites: string[];
  answers: string[];
  date: string;
  automatchSwitch: number | null;
  matchSwitch: number | null;
};

export type Params = {
  filter: Filter;
  page: number;
  perPage: number;
  sortBy: string;
  sort: "DESC" | "ASC";
};

type SearchParams = Partial<
  Omit<Params, "filter"> & { filter: Partial<Filter> }
>;

type SearchContextType = {
  params: Params;
  submit: (params: SearchParams) => void;
};

// === reducer

// === create context
const SearchContext = createContext<SearchContextType | undefined>(undefined);

// === context provider
const SearchProvider: React.FC = ({ children }) => {
  const [params, setParams] = useState<Params>({
    page: 1,
    perPage: 10,
    sortBy: "SYS_A_ID",
    sort: "ASC",
    filter: {
      text: "",
      sellers: [],
      favorites: [],
      status: "",
      answers: [],
      date: "",
      automatchSwitch: null,
      matchSwitch: null,
    },
  });

  const submit = useCallback(
    (newParams: SearchParams) => {
      // update params.
      newParams = {
        ...params,
        ...newParams,
        filter: { ...params.filter, ...newParams.filter },
      };
      // update params.
      setParams(newParams as Params);
    },
    [params]
  );

  return (
    <SearchContext.Provider
      value={{
        submit,
        params,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

// === custom hook
const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};

export { SearchProvider, useSearch };
