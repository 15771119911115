import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from "@mui/material";
import React, { useState } from "react";

type PanelType = {
  header?: React.ReactElement;
  actions?: React.ReactElement;
  minimizable?: boolean;
  sx?: SxProps<Theme> | undefined;
};

export const Panel: React.FC<PanelType> = (props) => {
  const { children, header, actions, minimizable = true, sx } = props;

  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={sx} className="test">
      <Box sx={{ py: 2, px: { lg: 3, xs: 2 } }}>
        <Box sx={{ display: "flex" }}>
          <Box mr="auto" py={1}>
            {header}
          </Box>
          {actions && expanded && <Box py={0.5}>{actions}</Box>}
          {minimizable && (
            <Box ml={2}>
              <IconButton onClick={handleExpandClick}>
                {expanded ? <Remove /> : <Add />}
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto">
        <CardContent sx={{ pt: 0, px: { lg: 3, xs: 2 } }}>
          {children}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export const PanelTitle = (props: TypographyProps) => (
  <Typography variant="sectionTitle" {...props} />
);

export const PanelSubTitle = (props: TypographyProps) => (
  <Typography variant="sectionDesc" {...props} />
);
