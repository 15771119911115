import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Panel, PanelSubTitle, PanelTitle } from "../../../../components/Panel";
import { PreferencesType, useKeyValues } from "./context/key-values";
import KeyValuesTable, { KeyValuesTableSkeleton } from "./KeyValuesTable";

const Preferences = () => {
  const { preferences, total, loading } = useKeyValues();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [col1, setCol1] = useState<PreferencesType[]>([]);
  const [col2, setCol2] = useState<PreferencesType[]>([]);
  const [col3, setCol3] = useState<PreferencesType[]>([]);

  useEffect(() => {
    if (preferences) {
      // number of columns.
      const colsNum = md ? 3 : sm ? 2 : 1;
      const rowsNum = Math.ceil(preferences.length / colsNum);
      setCol1(preferences.slice(0, rowsNum));
      setCol2(preferences.slice(rowsNum, rowsNum * 2));
      setCol3(preferences.slice(rowsNum * 2));
    }
  }, [sm, md, preferences]);

  return (
    <Panel
      header={
        <>
          <PanelTitle mb={0.5}>Præferencer hos aktører</PanelTitle>
          <PanelSubTitle>Baseret på {total} aktører</PanelSubTitle>
        </>
      }
    >
      <Grid container spacing={3}>
        {loading ? (
          <>
            {[1, 2, 3].map((i) => (
              <Grid item key={i} md={4} sm={6} xl={3}>
                <KeyValuesTableSkeleton />
              </Grid>
            ))}
          </>
        ) : (
          <>
            <Grid item md={4} sm={6} xl={3}>
              <KeyValuesTable preferences={col1} total={total} />
            </Grid>
            {col2.length > 0 && (
              <Grid item md={4} sm={6} xl={3}>
                <KeyValuesTable preferences={col2} total={total} />
              </Grid>
            )}
            {col3.length > 0 && (
              <Grid item md={4} sm={6} xl={3}>
                <KeyValuesTable preferences={col3} total={total} />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Panel>
  );
};

export default Preferences;
