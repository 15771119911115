// import { addDays, startOfDay } from "date-fns";
import React, { createContext, useContext, useState } from "react";

type Range = {
  startDate?: Date;
  endDate?: Date;
};

type DateRangeContextType = {
  range: Range;
  setRange: React.Dispatch<React.SetStateAction<Range>>;
};

// === context
const DateRangeContext = createContext<DateRangeContextType | undefined>(
  undefined
);

const DateRangeProvider: React.FC = ({ children }) => {
  const [range, setRange] = useState<Range>({
    // startDate: addDays(new Date(), -6),
    // endDate: startOfDay(new Date()),
  });

  return (
    <DateRangeContext.Provider value={{ range, setRange }}>
      {children}
    </DateRangeContext.Provider>
  );
};

// === custom hook
const useDateRange = () => {
  const context = useContext(DateRangeContext);
  if (context === undefined) {
    throw new Error("useDateRange must be used within a DateRangeContext");
  }
  return context;
};

export { DateRangeProvider, useDateRange };
