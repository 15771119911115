import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";
import { IframeModalProvider } from "../../../../context/iframe-modal";
import ActivityList from "./ActivityList";
import { ActivitiesProvider, useActivities } from "./context";
import FilterType from "./FilterType";
import Menu from "./Menu";

const Activities = () => {
  return (
    <div>
      <ActivitiesProvider>
        <IframeModalProvider>
          <Typography variant="h3" mb={3}>
            Aktiviteter
          </Typography>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ mb: 3 }}
              >
                <div>
                  <Typography variant="h5" sx={{ fontWeight: 400 }}>
                    Seneste aktiviteter
                  </Typography>
                  <Box sx={{ fontSize: 20, fontWeight: 600 }}>
                    <Total />
                  </Box>
                </div>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Box mr={2}>
                    <FilterType />
                  </Box>
                  <Menu />
                </Box>
              </Stack>
              <ActivityList />
            </CardContent>
          </Card>
        </IframeModalProvider>
      </ActivitiesProvider>
    </div>
  );
};

const Total = () => {
  const { activities } = useActivities();
  return <>{activities.length}</>;
};

export default Activities;
