import {
  Box,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { memo } from "react";
import { StyledOverviewTable } from "../../../../theme/styled-components";
import { PreferencesType } from "./context/key-values";

type KeyValuesTableType = {
  preferences: PreferencesType[];
  total: number;
  name?: string;
  totalText?: string;
};

const KeyValuesTable = memo(
  ({
    preferences,
    total,
    name = "Præference",
    totalText = "Antal",
  }: KeyValuesTableType) => {
    return (
      <Box sx={{ maxWidth: 286 }}>
        <StyledOverviewTable>
          <TableHead>
            <TableRow>
              <TableCell>{name}</TableCell>
              <TableCell align="center">{totalText}</TableCell>
              <TableCell align="center">%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {preferences.map((data, i) => (
              <TableRow key={i}>
                <TableCell>{data.Name}</TableCell>
                <TableCell align="center">{data.Total}</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#7BB3BD",
                    fontWeight: "500",
                  }}
                >
                  {total ? ((data.Total / total) * 100).toFixed(2) : 0}%
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledOverviewTable>
      </Box>
    );
  }
);

export const KeyValuesTableSkeleton = () => {
  return (
    <Box sx={{ maxWidth: 286 }}>
      <StyledOverviewTable>
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell align="center">
              <Skeleton />
            </TableCell>
            <TableCell align="center">
              <Skeleton />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[1, 2, 3, 4].map((i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell align="center">
                <Skeleton />
              </TableCell>
              <TableCell align="center">
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledOverviewTable>
    </Box>
  );
};

export default KeyValuesTable;
