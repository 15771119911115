import { Grid } from "@mui/material";
import { Panel, PanelSubTitle, PanelTitle } from "../../../../components/Panel";
import { useKeyValues } from "./context/key-values";
import KeyValuesTable, { KeyValuesTableSkeleton } from "./KeyValuesTable";

const SelectedPreferences = () => {
  const { selectedPreferences, total, loading } = useKeyValues();

  return (
    <Panel
      header={
        <>
          <PanelTitle mb={0.5}>Præferencer hos aktører</PanelTitle>
          <PanelSubTitle>Baseret på {total} aktører</PanelSubTitle>
        </>
      }
    >
      <Grid container spacing={3}>
        {loading ? (
          <>
            {[1, 2, 3].map((i) => (
              <Grid item key={i} md={4} sm={6} xl={3}>
                <KeyValuesTableSkeleton />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {selectedPreferences.map((data, i) => (
              <Grid key={i} item md={4} sm={6} xl={3}>
                <KeyValuesTable
                  preferences={data.Preferences}
                  total={total}
                  name={data.Name}
                  totalText={data.TotalText}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Panel>
  );
};

export default SelectedPreferences;
