import { Box, Grid, Stack, Typography } from "@mui/material";
import Activities from "../activities/Activities";
import ActorRoles from "./ActorRoles";
import { DateRangeProvider } from "./context/date-range";
import DateRangePickerFilter from "./DateRangePickerFilter";
import Economy from "./Economy";
import KeyValues from "./KeyValues";
import Kpi from "./Kpi";

const OverviewPage = () => {
  return (
    <DateRangeProvider>
      <Box mb={5}>
        <Stack direction="row" justifyContent="space-between">
          <Box mr={2}>
            <Typography variant="h2">Overblik</Typography>
            <Typography>
              Herunder er et overblik over alle aktører samt vigtige nøgletal.
            </Typography>
          </Box>
          <Box sx={{ alignSelf: "flex-end" }}>
            <DateRangePickerFilter />
          </Box>
        </Stack>
      </Box>

      <Grid container spacing={3}>
        {/* KPI */}
        <Grid item xs={12} lg={6}>
          <Kpi />
        </Grid>

        {/* actors distribution */}
        <Grid item xs={12} lg={6}>
          <ActorRoles />
        </Grid>
      </Grid>

      <Box py={3} />

      <KeyValues />

      <Box py={2} />

      <Economy />

      <Box py={5} />

      <Activities />
    </DateRangeProvider>
  );
};

export default OverviewPage;
