import { Box, Stack, Typography } from "@mui/material";
import SelectCity from "../SelectCity";
import { KeyValuesProvider, useKeyValues } from "./context/key-values";
import Preferences from "./Preferences";
import SelectedPreferences from "./SelectedPreferences";

const KeyValues = () => {
  return (
    <KeyValuesProvider>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <div>
          <Typography variant="h3">Nøgletal</Typography>
        </div>
        <div>
          <Box sx={{ width: 200 }}>
            <SelectCityField />
          </Box>
        </div>
      </Stack>

      <Preferences />
      <Box py={2} />
      <SelectedPreferences />
    </KeyValuesProvider>
  );
};

const SelectCityField = () => {
  const { city, setCity } = useKeyValues();
  return <SelectCity value={city} onChange={setCity} />;
};

export default KeyValues;
