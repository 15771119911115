import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useData } from "../../../context/data";
import OptionChip from "../../OptionChip";
import QuestionsSkeleton from "./QuestionsSkeleton";

const FieldFacilities = ({
  selected,
  onSelect,
}: {
  selected: string[];
  onSelect: (value: string) => void;
}) => {
  const { questionGroup } = useData();

  return (
    <Box mb={4}>
      <Typography variant="sectionTitle" mb={2.5}>
        Faciliteter
      </Typography>
      {questionGroup === null && <QuestionsSkeleton />}
      <Grid container spacing={1.5}>
        {questionGroup?.facilities.map((question, i) => {
          return question.Properties.Options.map((option) => {
            const answerValue = `${question.MPPQ_ID}_${option.Value}`;
            return (
              <Grid key={answerValue} item xs={6} sm={3} md={4} lg={4} xl={3}>
                <OptionChip
                  label={option.ShowValue}
                  value={answerValue}
                  selected={selected.includes(answerValue)}
                  handleClick={onSelect}
                />
              </Grid>
            );
          });
        })}
      </Grid>
    </Box>
  );
};

export default FieldFacilities;
