import { gql, useLazyQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";

// === gql
export const QUERY_STATISTICS = gql`
  query KeyValues($area: String) {
    statistics {
      KeyValues(area: $area) {
        TotalActors
        Preferences {
          Name
          Total
        }
        SelectedPreferences {
          Name
          TotalText
          Preferences {
            Name
            Total
          }
        }
      }
    }
  }
`;

// === types
export type PreferencesType = {
  Name: string;
  Total: number;
};

export type SelectedPreferencesType = {
  Name: string;
  TotalText: string;
  Preferences: PreferencesType[];
};

type Statistics = {
  KeyValues: {
    TotalActors: number;
    Preferences: PreferencesType[];
    SelectedPreferences: SelectedPreferencesType[];
  };
};

type KeyValuesContextType = {
  city: string | null;
  setCity: (value: string | null) => void;
  loading: boolean;
  total: number;
  preferences: PreferencesType[];
  selectedPreferences: SelectedPreferencesType[];
};

// === context
const KeyValuesContext = createContext<KeyValuesContextType | undefined>(
  undefined
);

const KeyValuesProvider: React.FC = ({ children }) => {
  const [city, setCity] = useState<string | null>(null);
  const [total, setTotal] = useState(0);
  const [preferences, setPreferences] = useState<PreferencesType[]>([]);
  const [selectedPreferences, setSelectedPreferences] = useState<
    SelectedPreferencesType[]
  >([]);

  const [getData, { data, loading }] = useLazyQuery<
    { statistics: Statistics },
    { area: string | null }
  >(QUERY_STATISTICS);

  useEffect(() => {
    if (data?.statistics) {
      const keyValues = data?.statistics.KeyValues;
      setTotal(keyValues.TotalActors);
      setPreferences(keyValues.Preferences);
      setSelectedPreferences(keyValues.SelectedPreferences);
    } else {
      setTotal(0);
      setPreferences([]);
      setSelectedPreferences([]);
    }
  }, [data]);

  useEffect(() => {
    getData({
      variables: {
        area: city,
      },
    });
  }, [city, getData]);

  return (
    <KeyValuesContext.Provider
      value={{
        city,
        setCity,
        loading,
        total,
        preferences,
        selectedPreferences,
      }}
    >
      {children}
    </KeyValuesContext.Provider>
  );
};

// === custom hook
const useKeyValues = () => {
  const context = useContext(KeyValuesContext);
  if (context === undefined) {
    throw new Error("useKeyValues must be used within a KeyValuesProvider");
  }
  return context;
};

export { useKeyValues, KeyValuesProvider };
