import { ApolloProvider } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import useSticky from "../../hooks/useSticky";
import client from "./apollo-client";
import OverviewPage from "./components/overview/OverviewPage";
import SearchActors from "./components/search/SearchActors";
import TopButtons from "./components/search/TopButtons";
import Tabs from "./components/Tabs";
import { ActorsProvider } from "./context/actors";
import { AddActorProvider } from "./context/add";
import { DataProvider } from "./context/data";
import { EditProvider } from "./context/edit";
import { LayoutProvider } from "./context/layout";
import { SearchProvider } from "./context/search";
import { SearchActorsProvider } from "./context/search-actors";

const ActorsPage = () => {
  return (
    <ApolloProvider client={client}>
      <DataProvider>
        <LayoutProvider>
          <ActorsProvider>
            <SearchProvider>
              <SearchActorsProvider>
                <EditProvider>
                  <AddActorProvider>
                    <Box mb={2}>
                      <AutoStickyHeader />
                    </Box>
                    <Box>
                      <Routes>
                        <Route index element={<OverviewPage />} />
                        <Route path="find" element={<SearchActors />} />
                      </Routes>
                    </Box>
                  </AddActorProvider>
                </EditProvider>
              </SearchActorsProvider>
            </SearchProvider>
          </ActorsProvider>
        </LayoutProvider>
      </DataProvider>
    </ApolloProvider>
  );
};

const AutoStickyHeader = () => {
  const { pathname } = useLocation();

  const { sticky, stickyRef } = useSticky();

  return (
    <>
      <Box
        ref={stickyRef}
        sx={{
          position: sticky ? "fixed" : "initial",
          top: 0,
          width: "100%",
          py: 2,
          px: 2.5,
          mx: -2.5,
          backgroundColor: "#F9FAFA",
          zIndex: 1200,
        }}
      >
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box mb={{ xs: 2, sm: 0 }}>
            <Tabs />
          </Box>
          {pathname === "/actors/find" && <TopButtons />}
        </Stack>
      </Box>
      {/* offset */}
      <Box
        sx={{
          height: sticky ? `${stickyRef.current?.clientHeight}px` : "0px",
        }}
      />
    </>
  );
};

export default ActorsPage;
