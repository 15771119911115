import { Box, Button, Card, CardContent, Divider, Grid } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useSearch } from "../../../context/search";
import FieldAutomatchSwitch from "./FieldAutomatchSwitch";
import FieldDate from "./FieldDate";
import FieldEconomy from "./FieldEconomy";
import FieldFacilities from "./FieldFacilities";
import FieldHousing from "./FieldHousing";
import FieldMatchSwitch from "./FieldMatchSwitch";
import FieldPeferences from "./FieldPeferences";
import FieldSelectSellers from "./FieldSelectSellers";
import FieldStatus from "./FieldStatus";
import FieldText from "./FieldText";

const SearchForm = () => {
  const { params, submit } = useSearch();

  // local states
  const [text, setText] = useState(params.filter.text);
  const [sellers, setSellers] = useState<string[]>([]);
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");

  const [answers, setAnswers] = useState<string[]>(params.filter.answers);
  const [favorites, setFavorites] = useState<string[]>(params.filter.favorites);

  const [automatchSwitch, setAutomatchSwitch] = useState<number | null>(
    params.filter.automatchSwitch
  );
  const [matchSwitch, setMatchSwitch] = useState<number | null>(
    params.filter.matchSwitch
  );
  const handleSearchButton = () => {
    submit({
      filter: {
        text,
        sellers,
        favorites,
        answers,
        status,
        date,
        automatchSwitch,
        matchSwitch,
      },
      page: 1,
    });
  };

  const handleAnswers = useCallback((value: string) => {
    setAnswers((selected) => {
      if (selected.includes(value)) {
        return selected.filter((val) => val !== value);
      } else {
        return [...selected, value];
      }
    });
  }, []);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2.5}>
          <Grid item xs={12} sm={3} xl={2}>
            <FieldText value={text} handleChange={setText} />
          </Grid>
          <Grid item xs={12} sm={3} xl={2}>
            <FieldSelectSellers
              selected={sellers}
              updateSelected={setSellers}
            />
          </Grid>
          <Grid item xs={12} sm={3} xl={2}>
            <FieldStatus value={status} onChange={setStatus} />
          </Grid>
          <Grid item xs={12} sm={3} xl={2}>
            <FieldDate value={date} onChange={setDate} />
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Grid container columnSpacing={{ xs: 3, lg: 5, xl: 7 }}>
          <Grid item xs={12} md={6}>
            <FieldHousing selected={answers} onSelect={handleAnswers} />
            <FieldEconomy selected={answers} onSelect={handleAnswers} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldFacilities selected={answers} onSelect={handleAnswers} />
            <FieldPeferences selected={favorites} onSelect={setFavorites} />
            <FieldMatchSwitch
              selected={matchSwitch}
              onChange={setMatchSwitch}
            />
            <FieldAutomatchSwitch
              selected={automatchSwitch}
              onChange={setAutomatchSwitch}
            />
          </Grid>
        </Grid>

        <Box pt={2.5} textAlign="right">
          <Button variant="contained" onClick={handleSearchButton}>
            START SØGNING
          </Button>
          {/* <SearchFormSubmit /> */}
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(SearchForm);
